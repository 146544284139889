<template>
  <div>
    <el-table
      v-loading="loading"
      height="67vh"
      :stripe="true"
      :data="lists">
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        width="200"
        label="通知主题"
        prop="send_name"
      >
      </el-table-column>
      <el-table-column
        label="发送方式"
        prop="send_type"
      >
      </el-table-column>
      <el-table-column
        label="已发送人数(人)"
        width="160"
        prop="_sum"
      >

      </el-table-column>

        <el-table-column
        label="点开率/唤回率"
        width="160"
        prop="openB"
      >

      </el-table-column>

      <!-- <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
      </el-table-column> -->

    </el-table>
      <page
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
  </div>
</template>

<script>
import CouponService from '@admin/services/CouponService'
// import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'CouponSendCount',
  data () {
    return {
      function: function (param) {
        return CouponService.getSendListCount(param)
      }
    }
  },

  components: {
    Page
  },
  mixins: [pagination]
}

</script>
<style lang='scss' scoped>
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right !important;
        margin: 0;
    }
}

</style>
